$nav_span_first: 29.375%;
$animation_speed: all 0.5s linear;
$breakpoint-tablet: 1023px;



.burger_icon{
  width: 80px;
  height: 80px;
  position: fixed;
  top: 20px;
  right: 20px;
  text-align: right;
  border-radius: 50px;
  z-index: 110;
  cursor: pointer;
    @media (max-width: 1023px) {
      width: 40px;
      height: 40px;
    }
  //border: 1px solid black;
  span{
    position: absolute;
    right: 20%;
    top: $nav_span_first;
    border-radius: 5px;
    width: 60%;
    height: 3px;
    background-color: black;
    transition: $animation_speed;
    & + span{
      top: $nav_span_first + 18.75%;
      opacity: 1;
      transition: $animation_speed;
      & + span{
        top: $nav_span_first + 37.5%;
        opacity: 1
      }
    }
  }
}

.burger_icon_open{
  span{
    transform: rotate(45deg);
    transition: $animation_speed;
    top: 47.0875%;
    background-color: white;
    & + span{
      opacity: 0;
      transition: $animation_speed;
      & + span{
        transform: rotate(-45deg);
        transition: $animation_speed;
        opacity: 1;
        top: 47.0875%;
      }
    }
  }
}


//navigation container

.contact_active{
  width: 100% !important;
  text-align: center;
  text-transform: uppercase;
  margin: 10px 0;
  font-size: 25px !important;
  transition: $animation_speed;
  color: rgba(0,0,0,0.06) !important;
   &::after{
     opacity: 1 !important;
     transition: $animation_speed;
     color: black !important;
   }
    &::before{
      opacity: 1 !important;
      transition: $animation_speed;
      color: black !important;
    }
}

.nav_container{
  position: fixed;
  top: 0;
  right: -40vw;
  height: 100vh;
  width: 20vw;
  transition: $animation_speed;
  box-sizing: border-box;
  z-index: 110;
    @media (max-width: 1023px) {
      right: -110vw;
      width: 100%;
      border: 2px solid black;
      box-sizing: border-box;
      background: rgba(0,0,0,0);
      border: none;
    }
    .circle_contact_holder {
      height: 20vh;
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 40vh;
      right: 0;
      width: 100%;
      &:after {
        width: 100vh;
        height: 100vh;
        border-radius: 50vh;
        background: black;
        position: absolute;
        top: -40vh;
        right: -50vh;
        display: block;
        z-index: -1;
        -webkit-box-shadow: inset -1px 2px 15px -2px rgba(0,0,0,0.29);
        -moz-box-shadow: inset -1px 2px 15px -2px rgba(0,0,0,0.29);
        box-shadow: inset -1px 2px 15px -2px rgba(0,0,0,0.29);
        content: " ";
      }
      a {
        display: block;
        font-size: 20px;
        color: white;
        padding-top: 10px;
          @media (max-width: 1023px) {
            text-align: right;
            padding-right: 20px;
            padding-top: 10px;
          }
      }
    }
    .contact_par_navigation {
      display: none;
      width: 100%;
      margin-top: 40vh;
      p {
        width: 100%;
        font-weight: 900;
        text-align: center;
      }
      .navigation_contact_items{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        align-items: center;
      a {
        width: 20%;
        float: left;
        img {
          width: 100%;
          max-width: 80px;
          padding: 0 10px;
          box-sizing: border-box;
        }
      }

      }
    }
}
.nav_container_open{
  right: 0vw;
  transition: $animation_speed;
}
.nav_links{
  display: flex;
  display: none;
  flex-direction: column;
  margin-top: 17vh;
  cursor: crosshair;
  color: black;
  .nav_a{
    font-weight: 900;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    margin: 10px 0;
    font-size: 20px;
    transform: scale(1.0);
    transition: $animation_speed;
    color: black;
    text-decoration: none;
    cursor: crosshair;
    &:hover{
      transform: scale(1.15);
      transition: $animation_speed;
      font-weight: 600;
    }
     &::after{
      content: " ";
      display: inherit;
      width: 14.70238%;
      height: 2px;
      background-color: black;
      transform: rotate(45deg) translateX(-50%);
      position: absolute;
      opacity: 0;
      transition: all 0.2s linear;
      left: 50%;
      bottom: 0;
     }
    &::before{
     content: " ";
     display: inherit;
     width: 14.70238%;
     height: 2px;
     background-color: black;
     transform: rotate(135deg) translateX(50%);
     position: absolute;
     opacity: 0;
     transition: all 0.2s linear;
     left: 50%;
     top: 0;
    }
  }
  .soon_tag{
    color: #dedcdc;
    font-weight: 400;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    margin: 10px 0;
    font-size: 20px;
    transform: scale(1.0);
    transition: $animation_speed;
    text-decoration: none;
    cursor: crosshair;
    &:hover{
      transform: scale(1.15);
      transition: $animation_speed;
      font-weight: 600;
    }
    &::before{
      content: "SOON";
      display: inline-block;
      color: black;
      font-weight: bold;
      font-size: 15px;
      transform: translateX(15px) rotate(22deg) translateY(0px);
      position: absolute;
      right: 50%;
    }
  }
}

.background{
  position: fixed;
  top: 0;
  right: -100vw;
  width: 100vw;
  height: 100vh;
  z-index: 110;
  background-color: rgba(255,255,255,0.5);
  transition: $animation_speed;
}
.background_open{
  top: 0;
  right: 0vw;
  transition: $animation_speed;
}

.nav_subscribe_container{
  text-align: center;
  width: 100%;
  position: absolute;
  padding: 0 5%;
  box-sizing: border-box;
  bottom: 5vh;
    @media (max-width: 1023px) {
      bottom: 15vh;
    }
  button{
    padding: 0;
    width: 100%;
    background-color: black;
    color: white;
    line-height: 30px;
    transition: $animation_speed;
    transform: scale(1.0);
    cursor: crosshair;
      &:hover{
        transition: $animation_speed;
        transform: scale(1.08);
      }
  }
  input {
    width: 100%;
    padding: 0;
    line-height: 30px;
    font-size: 15px;
    margin:0 0 10px 0;
    outline: none;
    border: none;
    border-bottom: 2px solid black;
    box-shadow: none !important;
    text-align: center;
    opacity: 1;
      &::placeholder{
        opacity: 1;
        color: #999999;
      }
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        opacity: 1;
        color: #999999;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        opacity: 1;
        color: #999999;
      }
  }
}
