.telaviv_slider_wrapper{
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: row;
  overflow: scroll;
  @media (max-width: 1023px) {
  flex-direction: column;
  }
  .beachcity_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    transition: $animation_speed;
    @media (max-width: 1023px) {
    width: 100%;
    height: 50%;
    flex-direction: row;
    }
    p{
      font-size: 45px;
      width: 100%;
      position: fixed;
      top: 50%;
      text-align: center;
      color: white;
      font-weight: bold;
      text-shadow: 2px 2px #000000;
      pointer-events: none;
      z-index: 1000000000;
    }
    .city-p{
      @media (max-width: 1023px) {
      height: auto;
      width: 100vw;
      top:75%;
      left: 0;
      transform: translateY(-50%);
      margin: 0;
      }
    }
    .beach-p{
      @media (max-width: 1023px) {
      height: auto;
      width: 100vw;
      top:25%;
      left: 0;
      transform: translateY(-50%);
      margin: 0;
      }
    }
  }
  .large_wrapper{
    width: 80%;
    transition: $animation_speed;
  }
  .small_wrapper{
    transition: $animation_speed;
    width: 25%;
  }
  .telaviv_image_container{
    background-position: center;
    background-size: cover;
    height: auto;
    position: relative;
    @media (max-width: 1023px) {
    height: 50vh;
    width: 300%;
    }
  }

  .gallery_photo_container{
    background-position: center;
    background-size: cover;
    height: 120vh;
    width: 100%;
    @media (max-width: 1023px) {
    height: 100%;
    width: 100%;
    }
  }
  .telavivbeach{
    background-image: url('http://underconstructioncity.com/imgs/telaviv_beach.jpg');
  }
  .telavivbeach2{
    background-image: url('http://underconstructioncity.com/imgs/telaviv_beach2.jpg');
  }
  .telavivbeach3{
    background-image: url('http://underconstructioncity.com/imgs/telaviv_beach3.jpg');
  }
  .telavivcity{
    background-image: url('http://underconstructioncity.com/imgs/telaviv_city.jpg');
  }
  .telavivcity2{
    background-image: url('http://underconstructioncity.com/imgs/telaviv_city2.jpg');
  }
  .telavivcity3{
    background-image: url('http://underconstructioncity.com/imgs/telaviv_city3.jpg');
  }

  .shaded_overlay{
      position: relative;
      &::after {
      opacity: 1 !important;
      pointer-events: none;
      transition: $animation_speed;
        @media (max-width: 1023px) {
          opacity: 0 !important;
        }
    }
  }
  .shaded_overlay_after{
    &::after {
    content: ' ';
    transition: $animation_speed;
    opacity: 0;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background: rgba(255, 255, 255, 0.65);
    }
  }
}
