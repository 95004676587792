.gallery_summary_wrapper{
  width: 33%;
  box-sizing: border-box;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  float: left;
  position: relative;
  z-index: 10;
  padding-bottom: 40px;
  @media (max-width: 1023px) {
    width: 100%;
    margin-bottom: 15vh;
  }
  .headline_and_coordinates{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 0 0 0;
    h3{
      font-size: 30px;
      line-height: 35px;
      color: black;
      font-weight: 300;
      text-align: center;
      width: 100%;
      text-transform: uppercase;
      cursor: default;
      font-weight: 300;
      margin: 0;
      padding: 0;
    }
    .coordinates{
      width: 100%;
      height: auto;
      max-height: 300px;
      overflow: hidden;
      margin: 10px 0 0 0;
      text-align: center;
      cursor: default;
      position: relative;
      transition: all 0.25s linear;
      font-size: 15px;
    }
  }
  .gallery_visual{
    padding: 0 ;
    margin: 0;
    box-sizing: border-box;
    position: relative;
    .img_shadow_coa{
      width: 84%;
      margin: 0 8%;
      box-sizing: border-box;
      z-index: 10;
      opacity: 0.3;
    }
    .img_coa{
      position: absolute;
      top: 0;
      left: 8%;
      width:84%;
      z-index: 1;
      transition: $animation_speed;
      transform: scale(1.0);
      &:hover{
      transition: $animation_speed;
      transform: scale(1.05);
      }
    }
  }
}
.soon_message{
  &::after{
    content: "SOON";
    display: inline-block;
    position: absolute;
    top: 50%;
    z-index: 10000000;
    opacity: 10;
    font-size: 50px;
    transform: translateY(-50%);
    font-weight: 600;
  }
}
.img_fade{
  opacity: 0.15;
}
.shadow_fade{
  opacity: 0 !important;
}
