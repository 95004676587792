.cercle_container {
    @media (max-width: 1023px) {
      overflow: hidden;
    }
  .gallery_holder {
    background-color: #fff;
    position: fixed;
    width: 100vw;
    top:0;
    left: -100vw;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000000;
    height: 100vh;
    overflow: hidden;
    transition: $animation_speed;
        @media (max-width: 1023px) {
          overflow: scroll;
          z-index: 5;
          height: 100vh;
        }
    &:after {
      content: " ";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100vh;
      width: 4vw;
      background-color: #fff;
        @media (max-width: 1023px) {
          display: none;
        }
    }
    @media (max-width: 1023px) {
      width:100vw;
      height: auto;
    }
    .project_image_extended {
      width: 100%;
      position: relative;
      overflow: hidden;
      @media (max-width: 1023px) {
        overflow: visible;
        position: absolute;
        top: 0;
        height: 100vh;
      }
      }
      .selectors_holder {
        width: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 5vh;
        transform: translateY(50%);
        @media (max-width: 1023px) {
          display: none;
        }
        .selectors_holder_single_item {
          width: 1vh;
          height: 1vh;
          border-radius: 50px;
          background-color: black;
          margin-right: 1vh;
          opacity: 0.2;
          transition: $animation_speed;
          transform: scale(1);
          cursor: pointer;
          &:hover{
          transition: $animation_speed;
          transform: scale(1.5);
          }
        }
        .img_gallery_selected {
          opacity: 1 !important;
          transition: $animation_speed;
          transform: scale(1.5);
        }
      }
      .all_img_holder {
        width: 10000%;
        top: 0;
        left: 0;
        margin-left: 0vh;
        transition: $animation_speed;
        @media (max-width: 1023px) {
          width: 100vw;
          display: flex;
          flex-direction: column;
        }
        .img_hodler {
          width: 100vw;
          height: 100vh;
          box-sizing: border-box;
          margin-left: 0;
          transition: all ease-in 0.5s;
          padding: 12.5vh 0;
          display: flex;
          justify-content: center;
          box-sizing: border-box;
          float: left;
          @media (max-width: 1023px) {
            width: 100vw;
            height: auto;
            padding: 0;
            flex-direction: column;
          }
          img {
            height: 75vh;
            @media (max-width: 1023px) {
              width: 100vw;
              height: auto;
            }
          }
      }
    }
    .project_gallery_command_holder {
      width: 10vw;
      position: absolute;
      bottom: 3.5vh;
      left: 5vh;
      @media (max-width: 1023px) {
        display: none;
      }
      .previous_arrow {
        display: block;
        opacity: 1;
        width: 2vh;
        height: 2vh;
        border-left: 2px solid black;
        border-bottom: 2px solid black;
        transform: rotate(45deg) scale(1);
        float: left;
        margin-right: 2vh;
        cursor: pointer;
        transition: $animation_speed;
        &:hover{
        transition: $animation_speed;
        transform: rotate(45deg) scale(1.25);
        }
      }
      .next_arrow {
        display: block;
        opacity: 1;
        width: 2vh;
        height: 2vh;
        border-right: 2px solid black;
        border-top: 2px solid black;
        transform: rotate(45deg) scale(1);
        float: left;
        cursor: pointer;
        transition: $animation_speed;
        &:hover{
        transition: $animation_speed;
        transform: rotate(45deg) scale(1.25);
        }
      }
      .arrow_disabled {
        pointer-events: none;
        opacity: 0.2 !important;
        transition: $animation_speed;
      }
    }
  }
  .gallery_holder_open {
    left: 0;
    z-index: 100000000;
    background: white;
    transition: $animation_speed;
    @media (max-width: 1023px) {
      height: 100vh;
    }
  }

  .events_list {
    padding-top: 15vh;
    width: 100vw;
    float: right;
    padding-bottom: 20vh;
    @media (max-width: 1023px) {
      width: 100%;
      padding: 10vh 0 5vh 0;
      display: flex;
      flex-direction: column;
    }
    .single_event {
      min-height: 30vh;
      width: 33.333vw;
      height: 33.33vw;
      float: left;
      box-sizing: border-box;
      transition: $animation_speed;
      border: 0px solid white;
      box-sizing: border-box;
      @media (max-width: 1023px) {
        width: 100vw;
        padding: 15px 15px 0 15px;
        height: auto;
      }
      &:hover {
        transition: $animation_speed;
        border: 15px solid white;
        box-sizing: border-box;
        .stickey_heder h4{
          transform: scale(1.5);
          transition: $animation_speed;
        }
      }
      .stickey_heder{
        box-sizing: border-box;
        position: sticky;
        height: 100%;
        top: 0;
        text-transform: uppercase;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 1023px) {
          height: 100vw;
        }
        h4 {
          font-size: 30px;
          padding: 30px 0 0 0;
          margin: 0;
          position: absolute;
          top: 0;
          color: white;
          transform: scale(1.0);
          transition: $animation_speed;
        }
        img {
          height: 100%;
        }
      }
    }
  }

  .cercle_backdrop {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgba(255,255,255,0.8);
    top: 0;
    left: -100vw;
    opacity: 0;
    transition: $animation_speed;
    z-index: 10;
    @media (max-width: 1023px) {
      display: block;
      left: 0;
      top: -100vh;
    }
  }
  .cercle_backdrop_down {
    transition: $animation_speed;
    left: 0vw;
    opacity: 0.7;
    @media (max-width: 1023px) {
      left: 0;
      display: block;
      top: 0vh;
      opacity: 1;
    }
  }
}

.hide_element {
  display: none !important;
}
