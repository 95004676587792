.full_height_text_container{
  width: 40%;
  padding-left: 120px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100vh;
  text-align: center;
  justify-content: center;
  h4{
    font-size: 30px;
    line-height: 35px;
    color: black;
    font-weight: 300;
    text-align: left;
    width: 80%;
    padding-left: 10%;
    text-transform: uppercase;
    cursor: default;
    font-weight: 300;
    margin: 0;
    padding: 0;
  }

  .year_slider{
    width: auto;
    display: inline;
    text-align: left;
    font-size: 12px;
    font-style: italic;
    font-weight: 700;
    line-height: 14px;
  }
  .architect_name_slider{
    width: auto;
    display: inline;
    text-align: left;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 14px;

  }
  .info_text_slider{
    width: 100%;
    text-align: left;
    font-size: 15px;
    line-height: 18px;
    color: black;
  }
}
