$animation_speed: all 0.25s linear;


.articles_wrapper{
  width: 100%;
  height: auto;
  padding: 10% 10% 0 10%;
  box-sizing: border-box;
    @media (max-width: 1023px) {
      padding: 15% 5% 0 5%;
    }
    .team_description {
      padding: 0 5% 3vh 5%;
      margin: 0;
      text-align: center;
    }
    .theteam_people {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 4vh 0 0 0;
      .sigle_team_member {
        width: 33.33%;
        text-align: center;
        img {
          padding: 0 15% 2vh 15%;
          margin: 0;
          width: 100%;
          box-sizing: border-box;
        }
        h3 {
          padding: 0 5% 0.5vh 5%;
          margin: 0;
          width: 100%;
          box-sizing: border-box;
        }
        h6 {
          padding: 0 5%;
          width: 100%;
          box-sizing: border-box;
        }
        h4 {
          padding: 0 5%;
          margin: 0;
          width: 100%;
          box-sizing: border-box;
        }
      }
    }
}
