.thankyou{
  background-color: white;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p{
    text-align: center;
    width: 100%;
    margin: 0;
    padding:0 0 20px 0;
  }
  h3{
    font-weight: bold;
    text-align: center;
    width: 100%;
    margin: 0;
    padding:0 0 30px 0;
    line-height: 32px;
  }
  a{
    text-decoration: none;
    color: black;
    font-weight: lighter;
  }
}
