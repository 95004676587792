$flexboxGallerySpacing: 2px;



  .segment_title{
    position: fixed;
    line-height: 80px;
    font-size: 45px;
    box-sizing: border-box;
    vertical-align: middle;
    left: 0;
    top: 0;
    margin-top: 0;
    padding: 20px 0 0 7%;
    width: 50%;
    z-index: 10;
    font-weight: bold;
    text-align: left;
    text-transform: uppercase;
    @media (max-width: 1023px) {
      display: none;
    }
    span{
      font-size: 15px;
      padding-left: 20px;
    }
  }
  .segment_title_project {
    position: absolute;
    line-height: 80px;
    font-size: 45px;
    box-sizing: border-box;
    vertical-align: middle;
    left: 0;
    top: 0;
    margin-top: 0;
    padding: 20px 0 0 8%;
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-transform: uppercase;
    z-index: 10000000000000000;
    font-weight: bold;
    text-align: left;
    @media (max-width: 1023px) {
      display: flex;
      align-items: center;
      justify-content: center;
      position: sticky;
      line-height: 40px;
      font-size: 25px;
      margin: 0 0 0 18vw;
      padding: 20px 0 20px 0;
      width: 82vw;
      text-align: center;
    }
      .previous_arrow {
        display: block;
        opacity: 1;
        width: 2vh;
        height: 2vh;
        margin-right: 1vw;
        border-left: 2px solid black;
        border-bottom: 2px solid black;
        transform: rotate(45deg) scale(1);
        float: left;
        cursor: pointer;
        transition: $animation_speed;
        @media (max-width: 1023px) {
          margin-right: 20px;
        }
        &:hover{
        transition: $animation_speed;
        transform: rotate(45deg) scale(1.25);
        }
      }
      .next_arrow {
        display: block;
        opacity: 1;
        width: 2vh;
        height: 2vh;
        margin-left: 1vw;
        border-right: 2px solid black;
        border-top: 2px solid black;
        transform: rotate(45deg) scale(1);
        float: left;
        cursor: pointer;
        transition: $animation_speed;
        @media (max-width: 1023px) {
          margin-left: 20px;
        }
        &:hover{
        transition: $animation_speed;
        transform: rotate(45deg) scale(1.25);
        }
      }
      .arrow_disabled {
        pointer-events: none;
        opacity: 0.2 !important;
        transition: $animation_speed;
      }
  }
  .segment_title_full{
    width: 100%;
  }

.gallery_wrapper{
  padding: 10%;
}
.gallery_container{
  position: fixed;
  top: 0;
  left: -100vw;
  z-index: 109;
  opacity: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  background-color: white;
  transition: $animation_speed;
  .closeGallery{
    position: fixed;
    width: 80px;
    height: 80px;
    top: 20vh;
    left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1023px) {
      width: 40px;
      height: 40px;
      top: 15vh;
      left: 10px;
    }
    &::before{
      content: "";
      display: inline-block;
      width: 50%;
      height: 50%;
      border-top: 1px solid black;
      border-left: 1px solid black;
      transform: rotate(-45deg) translateX(15px);
    }
  }

  .inner_gallery{
    width: 100%;
    box-sizing: border-box;
    height: auto;
    overflow: hidden;
    padding: 10%;
    @media (max-width: 1023px) {
    padding: 20% 20px 10% 65px;
    }
    .full_width_img_gallery{
      clear: both;
      box-sizing: border-box;
      margin: 0px 0px 80px 0px;
      @media (max-width: 1023px) {
        margin: 0px 0px 30px 0px;
        padding-bottom: 8px;
      }
      img{
        width: 100%;
      }
      figcaption{
        width: 100%;
        text-align: center;
        padding: 0;
        @media (max-width: 1023px) {
          font-size: 8px;
          text-align: left;
        }
      }
    }

    .background_images_container{
      width: 100%;
      display: flex;
      flex-direction: column;
      height: auto;
      justify-content: center;
      align-items: center;
      .feed_one{
        width: 100%;
        height: 35vh;
        background-image: url('http://underconstructioncity.com/imgs/bg_wide.jpg');
        background-position: bottom;
        background-size: cover;
        box-sizing: border-box;
      }
      .feed_multiple_holder{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-top: $flexboxGallerySpacing;
        box-sizing: border-box;
        @media (max-width: 1023px) {
          flex-direction: column;
        }
        .vertical_flex{
          display: flex;
          flex-direction: column;
          height: 120vh;
          @media (max-width: 1023px) {
          height: 100vh;
          }
        }
        .gallery_w1{
          width: 70%;
          @media (max-width: 1023px) {
            width: 100%;
          }
        }
        .gallery_w2{
          width: 30%;
          box-sizing: border-box;
          padding-left: $flexboxGallerySpacing;
          @media (max-width: 1023px) {
            width: 100%;
          }
        }
        .gallery_v1{
          width: 100%;
          height: 70%;
          background-image: url('http://underconstructioncity.com/imgs/svetozara_markovica.jpeg');
          background-position: center;
          background-size: cover;
          padding-bottom: $flexboxGallerySpacing;
          box-sizing: border-box;
        }
        .gallery_v2{
          width: 100%;
          height: 30%;
          background-image: url('http://underconstructioncity.com/imgs/tas.JPG');
          background-position: center;
          background-size: cover;
        }
        .gallery_v3{
          width: 100%;
          height: 40%;
          background-image: url('http://underconstructioncity.com/imgs/zeleni_venac.jpg');
          background-position: center;
          background-size: cover;
          padding-bottom: $flexboxGallerySpacing;
          box-sizing: border-box;
        }
        .gallery_v4{
          width: 100%;
          height: 60%;
          background-image: url('http://underconstructioncity.com/imgs/nikole_pasica.JPG');
          background-position: center;
          background-size: cover;
        }
      }
    }

    .segment_divider_gallery{
      width: 100%;
      height: 15vh;
      @media (max-width: 1023px) {
        height: 5vh;
      }
    }
  }

  .city_gallery_headline{
    position: fixed;
  	transform: rotate(180deg);
    writing-mode: vertical-rl;
    transform: scaleX(-1) scaleY(-1);
    width: auto;
    height: auto;
    line-height: 80px;
    font-size: 45px;
    box-sizing: border-box;
    vertical-align: middle;
    left: 0;
    bottom: 0;
    margin-top: 0;
    padding: 50px 10px 0 0;
    z-index: 10;
    font-weight: 100;
    text-align: left;
    @media (max-width: 1023px) {
      line-height: 40px;
      font-size: 30px;
      padding: 20px 10px 0 0;
    }
    span{
      font-size: 15px;
      padding-top: 20px;
      @media (max-width: 1023px) {
        font-size: 12px;
      }
    }
  }
}
.gallery_container_open{
  transition: $animation_speed;
  left: 0vw;
  opacity: 1;
}

.tripiece_gallery_wrapper{
  width: 100%;
  display: flex;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
  .gallery_controler{
    float: left;
    width: 30%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 1023px) {
    width: 100%;
    }
    .slider_buildings_selector_description_holder{
      width: 100%;
      height: 100px;
      .slider_buildings_selector_description_wrapper{
        padding-top: 20px;
        position: relative;
        .single_building_slider_description{
          position: absolute;
          width: 100%;
          top: 10px;
          left: 0;
          transition: $animation_speed;
          opacity: 0;
          p{
            margin: 0;
          }
        }
        .single_building_slider_description_active{
          transition: $animation_speed;
          opacity: 1;
        }
      }
    }
    .slider_buildings_selector_holder{
      width: 100%;
      height: auto;
      position: relative;
      @media (max-width: 1023px) {
        display: flex;
        flex-direction: row;
      }
      &::after{
        display: inline-block;
        content: " ";
        width: 30%;
        height: 1px;
        background-color: black;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
      .slider_buildings_selector{
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        font-weight: normal;
        transform: scale(1.0);
        cursor: pointer;
        transition: $animation_speed;
        opacity: 0.3;
        @media (max-width: 1023px) {
          margin: 10px 0;
          font-size: 14px;
          line-height: 18px;
        }
        &:hover{
        transform: scale(1.1);
        font-weight: bold;
        opacity: 1;
        transition: $animation_speed;
        }
      }
      .slider_buildings_selector_active{
        transform: scale(1.1);
        font-weight: bold;
        opacity: 1;
      }
    }
    p{
      width: 100%;
      text-align: center;
      @media (max-width: 1023px) {
        font-size: 12px;
      }
    }
  }
  .tripiece_gallery_sliders{
    width: 70%;
    height: auto;
    float: left;
    @media (max-width: 1023px) {
      width: 100%;
      height: 70vh;
      position: relative;
    }
    .mobile_switch_info{
      display: none;
      @media (max-width: 1023px) {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10000;
        transition: $animation_speed;
      }
      .mobile_switch_info_text{
        @media (max-width: 1023px) {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(255,255,255,0.6);
          opacity: 1;
          transition: $animation_speed;
        }
        p{
          @media (max-width: 1023px) {
            font-weight: normal;
            font-weight: 400;
            text-align: center;
            font-size: 12px;
            transform: translateY(-50%);
          }
        }
      }
    }
    .tripiece_image_slider{
      width: 50%;
      overflow: hidden;
      float: left;
      transition: $animation_speed;
      @media (max-width: 1023px) {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      .images_wrapper{
        width: 300%;
        overflow: hidden;
        margin-left: 0px;
        transition: $animation_speed;
        img{
          width: 33.3333%;
          float: left;
          box-sizing: border-box;
          padding: 3%;
        }
      }
    }
  }
}

.opacity_switch{
  opacity: 0;
  transition: $animation_speed;
}
