@import "Components/Navigation/navigation.scss";
@import "Components/Portfolio/Portfolio.scss";
@import "Components/TheTeam/theteam.scss";
@import "Components/Footer/footer.scss";
@import "Components/ThankYou/thankyou.scss";
@import "Components/Gallery/gallery.scss";
@import "Components/Gallery/BelgradeGallery/belgradegallery.scss";
@import "Components/Gallery/TelAvivGallery/telavivgallery.scss";
@import "Components/Gallery/Stockholm/stockholmGallery.scss";
@import "Components/HomePage/HomePage.scss";


@font-face {
  font-family: 'GeosansLight';
  src: url('./fonts/GeosansLight.ttf') format('truetype');
}

body{
  font-family: 'GeosansLight', sans-serif;
}

.home_logo{
  height: 80px;
  width: 80px;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1100000000;
  @media (max-width: 1023px) {
    width: 40px;
    height: 40px;
  }
}

a{
  text-decoration: none;
  color: black;
}

p, h1, h2, h3, h4, h5{
  cursor: default;
}

.headline_float_right{
  font-size: 30px;
  line-height: 30px;
  color: black;
  font-weight: 300;
  width: auto;
  float: right;
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  padding-left: 30px;
  @media (max-width: 1023px) {
    padding: 0 0 10px 0;
  }
}

.description_small{
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  width: 30%;
  float: right;
  padding: 0 0 40px 0;
  margin: 0;
  text-align: right;
  @media (max-width: 1023px) {
    width: 100%;
    padding: 0 0 20px 0;
  }
  span{
    display: none;
    @media (max-width: 1023px) {
      display: block;
    }
  }
}

.description_left_aligned{
  float: left;
  padding-bottom: 30px;
  width: 50%;
  @media (max-width: 1023px) {
    width: 100%;
  }
}
.divider_left{
  float: left;
  text-align: left;
  width: 100%;
  padding:0 30px 10px 0;
  @media (max-width: 1023px) {
    float: left;
    text-align: center;
    width: 50%;
    padding: 0;
  }
}
.w50{
  width: 50%;
  float: left;
  position: relative;
  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.unselected{
  opacity: 0.3;
  transition: $animation_speed;
  &:hover{
    opacity: 0.5;
    transition: $animation_speed;
  }
}
