
.home_segment {
  width: 100%;
  margin-top: 15vh;
  padding: 5vh 10vw;
  box-sizing: border-box;
  img {
    width: 50%;
  }
}
