.footer_container{
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &::before{
    content: " ";
    display: inline-block;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 3px;
    background-color: black;
    @media (max-width: 1023px) {
    width: 100%;
    }
  }
  .footer_logo_copyright {
    padding-left: 20px;
    width: 20%;
    display: flex;
    justify-content: start;
    align-items: center;
    .footer_logo{
      margin-top: 20px;
      max-width: 80px;
      @media (max-width: 1023px) {
        margin-top: 20px;
        width: 20%;
        width: 40px;
        height: 40px;
      }
    }
    .copyright{
      font-size: 10px;
      margin-top: auto;
      margin-bottom: 0;
      padding-bottom: 15px;
      box-sizing: border-box;
      @media (max-width: 1023px) {
        font-size: 8px;
      }
    }
  }
  .footer_contact_info {
    width: 80%;
    display: flex;
    flex-direction: row-reverse;
    padding-right: 40px;
    box-sizing: border-box;
    a {
      width: 10%;
      max-width: 80px;
      padding: 0 0 0 25px;
      box-sizing: border-box;
      img {
        max-width: 100%;
      }
    }
  }
}
